@import './../../sass/variables';

@function rem($px) {
  @return ($px / 16) + rem;
}

.text {
  margin-top: 0;
  margin-bottom: 0;
}

.isCentered {
  margin-right: auto;
  margin-left: auto;
}

.isBalanced {
  text-wrap: balance;
}

// Align
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.justify {
  text-align: justify;
}

// Weight
.regular {
  font-weight: $weight-normal;
}

.medium {
  font-weight: $weight-medium;
}

.bold {
  font-weight: $weight-bold;
}

// Display
.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

// Colors
.color-white {
  color: $colorWhite;
}

.color-gray100 {
  color: $colorGray100;
}

.color-gray200 {
  color: $colorGray200;
}

.color-gray300 {
  color: $colorGray300;
}

.color-gray400 {
  color: $colorGray400;
}

.color-gray500 {
  color: $colorGray500;
}

.color-gray800 {
  color: $colorGray800;
}

.color-gray900 {
  color: $colorGray900;
}

.color-blue300 {
  color: $colorBlue300;
}

.color-blue700 {
  color: $colorBlue700;
}

// Opacity
.opacity-50 {
  opacity: 0.5;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-100 {
  opacity: 1;
}

// Transform
.transform-uppercase {
  text-transform: uppercase;
}

// Sizes
.size-inherit {
  font-size: inherit;
}

.size-10 {
  font-size: rem(10);
  line-height: rem(12);
}

.size-12 {
  font-size: rem(11);
  line-height: rem(14);
}

.size-13 {
  font-size: rem(12);
  line-height: rem(16);
}

.size-14 {
  font-size: rem(13);
  line-height: rem(18);
}

.size-15 {
  font-size: rem(14);
  line-height: rem(22);
}

.size-16 {
  font-size: rem(15);
  line-height: rem(24);
}

.size-18 {
  font-size: rem(16);
  line-height: rem(26);
}

.size-20 {
  font-size: rem(16);
  line-height: rem(28);
}

.size-24 {
  font-size: rem(20);
  line-height: rem(30);
}

.size-30 {
  font-size: rem(26);
  line-height: rem(38);
}

.size-32 {
  font-size: rem(22);
  line-height: rem(34);
}

.size-34 {
  font-size: rem(24);
  line-height: rem(38);
}

.size-40 {
  font-size: rem(26);
  line-height: rem(42);
}

.size-44 {
  font-size: rem(28);
  line-height: rem(42);
}

.size-48 {
  font-size: rem(32);
  line-height: rem(48);
}

.size-56 {
  font-size: rem(32);
  line-height: rem(48);
}

.size-64 {
  font-size: rem(40);
  line-height: rem(47);
}

@include media-breakpoint-up(md) {
  .size-20 {
    font-size: rem(20);
    line-height: rem(37);
  }

  .size-30 {
    font-size: rem(30);
    line-height: rem(44);
  }

  .size-34 {
    font-size: rem(30);
    line-height: rem(40);
  }

  .size-44 {
    font-size: rem(44);
    line-height: rem(60);
  }

  .size-48 {
    font-size: rem(48);
    line-height: rem(65);
  }

  .size-56 {
    font-size: rem(48);
    line-height: rem(65);
  }

  .size-64 {
    font-size: rem(40);
    line-height: rem(66);
  }
}

@include media-breakpoint-up(lg) {
  .size-10 {
    font-size: rem(10);
    line-height: rem(16);
  }

  .size-12 {
    font-size: rem(12);
    line-height: rem(18);
  }

  .size-13 {
    font-size: rem(13);
    line-height: rem(20);
  }

  .size-14 {
    font-size: rem(14);
    line-height: rem(24);
  }

  .size-15 {
    font-size: rem(15);
    line-height: rem(28);
  }

  .size-16 {
    font-size: rem(16);
    line-height: rem(30);
  }

  .size-18 {
    font-size: rem(18);
    line-height: rem(34);
  }

  .size-20 {
    font-size: rem(20);
    line-height: rem(36);
  }

  .size-24 {
    font-size: rem(24);
    line-height: rem(38);
  }

  .size-32 {
    font-size: rem(32);
    line-height: rem(30);
  }

  .size-34 {
    font-size: rem(34);
    line-height: rem(46);
  }

  .size-40 {
    font-size: rem(40);
    line-height: rem(50);
  }

  .size-64 {
    font-size: rem(64);
    line-height: rem(74);
  }
}

@include media-breakpoint-up(xl) {
  .size-56 {
    font-size: rem(56);
    line-height: rem(76);
  }
}
