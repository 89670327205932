@import '../../../sass/variables';

.wrapper {
  display: none;
  align-items: center;
  min-height: 35px;
  padding: 5px 0;
  font-size: 0.8125rem;
  color: $colorWhite;
  background-color: $colorBlue300;
  background-image: $blueNoisePattern;
  background-repeat: repeat;
  background-size: 35px 35px;

  .phoneWrapper {
    display: none;
  }

  &.isVisibleOnMobile {
    display: flex;
  }
}

.tooltip {
  margin-left: 5px;
  color: $colorWhite;
}

@media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .wrapper {
    background-image: url('/image/noise.png');
    background-size: 70px 70px;
  }
}

.container {
  width: 100%;
  max-width: 540px;
  padding: 0 30px;
  margin: 0 auto;
}

.phone {
  font-weight: $weight-normal;
}

.link {
  margin-right: 25px;
  font-weight: $weight-normal;

  &:last-child {
    margin: 0;
  }
}

.row {
  display: flex;
  align-items: center;
  height: 100%;
}

.linksWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@include media-breakpoint-up(sm) {
  .container {
    max-width: 720px;
    padding: 0 20px;
  }

  .linksWrapper {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    display: flex;

    .phoneWrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      transform: translateY(-1px);
    }

    .phoneIcon {
      transform: translateY(1px);
    }

    &.isVisibleOnMobile {
      display: none;
    }
  }

  .container {
    max-width: 960px;
    padding: 0 15px;
  }

  .linksWrapper {
    display: inline-block;
    width: auto;
  }
}

@include media-breakpoint-up(xl) {
  .container {
    max-width: 1220px;
  }
}
